<template>
  <!--
   nota: posiblemente sea mejor pasar directamente el objeto Signer a este componente
   para no tener tantos props, pero aun no se cual es la mejor opcion, lo dejare para despues
  -->
  <vx-card
    noShadow cardBorder
    class="mb-5"
  >
    <!-- Signer info -->
    <vs-list>
      <vs-list-header
        icon="account_circle"
        :title="name"
        :color="isSigValid? 'success': 'danger'"
      >
      </vs-list-header>
      <vs-list-item
        title="Algoritmo de digestión"
        :subtitle="digestAlg">
      </vs-list-item>
      <vs-list-item
        title="Fecha de firmado"
        :subtitle="sigDate">
      </vs-list-item>
      <vs-list-item
        title="Emisor del certificado"
        :subtitle="cert['issuer']">
      </vs-list-item>
      <vs-list-item
        title="No. Certificado"
        :subtitle="cert['serialNumber']">
      </vs-list-item>
      <!--Validaciones Signers-->
      <vs-list-item
        title="Validez de firma"
        :subtitle="isSigValid? 'La firma es válida': 'La firma no es válida' ">
        <feather-icon
          :icon="isSigValid? 'CheckCircleIcon': 'AlertTriangleIcon' "
          :svgClasses="isSigValid? 'text-success' : 'text-danger'"
        />
      </vs-list-item>
      <vs-list-item
        title="Validez de certificado"
        :subtitle="cert['validOnSignatureDate']
                ? 'El certificado es válido en la fecha de firma': 'Certificado Apócrifo' ">
        <feather-icon
          :icon="cert['validOnSignatureDate']?  'CheckCircleIcon': 'AlertTriangleIcon' "
          :svgClasses="cert['validOnSignatureDate']? 'text-success' : 'text-danger'"
        />
      </vs-list-item>

    </vs-list>

    <!-- Timestamp -->
    <vs-list v-if="timestamp">
      <vs-list-header
        icon="access_time"
        title="Estampado de tiempo"
        :color="timestamp['success']? 'success': 'danger'"
      >
      </vs-list-header>
      <vs-list-item
      title="Validez de estampado de tiempo"
      :subtitle="timestamp['success']
                  ? 'El estampado de tiempo es válido'
                  : 'El estampado de tiempo no corresponde con el  documento firmado' "
      >
      <feather-icon
        :icon="timestamp['success']?  'CheckCircleIcon': 'AlertTriangleIcon' "
        :svgClasses="timestamp['success']? 'text-success' : 'text-danger'"
      />
      </vs-list-item>

      <vs-list-item
        title="Emisor"
        :subtitle="timestamp['signerResults'][0]['signerName']">
      </vs-list-item>

      <vs-list-item
        title="Fecha de emisión"
        :subtitle="timestamp['signerResults'][0]['signatureDateTime']">
      </vs-list-item>

    </vs-list>

    <!--Timestamp render cuando no existe -->
    <vs-list v-else-if="checkTimestamp">
      <vs-list-header
        icon="access_time"
        title="Estampado de tiempo"
        color="danger"
      >
      </vs-list-header>
      <vs-list-item title="La firma no contiene estampado de tiempo">
        <feather-icon icon="AlertTriangleIcon" svgClasses="text-danger"/>
      </vs-list-item>
    </vs-list>

  </vx-card>
</template>

<script>
export default {
  name: 'signer',
  props: {
    /* signature: String,*/
    isSigValid: Boolean,
    name: String,
    sigDate: String,
    digestAlg: String,
    cert: Object,
    timestamp: Object,
    checkTimestamp: {
      type: Boolean,
      default: false
    }
  },
  methods : {
    shouldRenderTs () {
      return this.timestamp || this.checkTimestamp
    }
  }
}
</script>

<style scoped>

</style>
