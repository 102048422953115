import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue')
        },
        {
          path: '/validador-firmas',
          name: 'V-sign',
          component: () => import('./views/V-sign.vue')
        },
        {
          path: '/validador-pdf-signature',
          name: 'V-pdf',
          component: () => import('./views/V-pdf.vue')
        },
        {
          path: '/validador-constancia',
          name: 'V-nom',
          component: () => import('./views/V-nom.vue')
        },
        {
          path: '/validador-xml',
          name: 'V-xml',
          component: () => import('./views/V-xml.vue')
        }
      ]
    },

    // Redirect to 404 page, if no match found
    {
      path: '*',
      component: () => import('./views/pages/Error404.vue')
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
