<template>
  <vs-list>
    <vs-list-header
      icon="stars"
      title="Constancia de conservación"
      :color="isTsrValid? 'success': 'danger'">
    </vs-list-header>

    <!--Detalles NOM-->

    <vs-list-item title="PSC" :subtitle="signer['signerName']"></vs-list-item>
    <vs-list-item title="Política de emisión" :subtitle="policy"></vs-list-item>
    <vs-list-item title="Fecha de emisión" :subtitle="signer['signatureDateTime']">
    </vs-list-item>

    <!-- Validaciones NOM -->
    <vs-list-item
      title="Validez de constancia"
      :subtitle="isImpMsgValid
              ? 'El hash del documento es el que ampara la constancia'
              : 'El hash del documento NO es el que ampara la constancia' "
    >
      <feather-icon
        :icon="isImpMsgValid? 'CheckCircleIcon': 'AlertTriangleIcon' "
        :svgClasses="isImpMsgValid? 'text-success' : 'text-danger'"
      />
    </vs-list-item>
    <vs-list-item
      title="Firma del PSC"
      :subtitle="signer['signatureValid']
              ? 'La firma del PSC es correcta'
              : 'La firma del PCS NO es correcta, posiblemente alterada'">
      <feather-icon
        :icon="signer['signatureValid']
              ? 'CheckCircleIcon': 'AlertTriangleIcon'"
        :svgClasses="signer['signatureValid']? 'text-success' : 'text-danger'">
      </feather-icon>
    </vs-list-item>
  </vs-list>
</template>

<script>
export default {
  name: 'nom',
  props: {
    isTsrValid: Boolean,
    policy: String,
    isImpMsgValid: Boolean,
    signer: Object
  }
}
</script>

<style scoped>

</style>
