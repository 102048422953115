// axios
import axios from 'axios'

const baseURL = 'https://validador.digitafirma.com/api'

export default axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
