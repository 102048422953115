<template>
  <vx-card class="mb-5" id="doc_details">
    <h4 class="mb-5">
      <feather-icon icon="FileTextIcon" svgClasses="stroke-current h-5 w-5 text-primary"/>
      Detalles del Documento
    </h4>

    <vs-list-item
      title="Nombre"
      :subtitle="name">
    </vs-list-item>

    <vs-list-item
      title="HASH"
      :subtitle="hash">
    </vs-list-item>

    <vs-list-item v-if="signatureType"
      title="Estrategia de firmado"
      :subtitle="signatureType">
    </vs-list-item>

  </vx-card>
</template>

<script>
export default {
  name: 'doc-details',
  props: {
    name: String,
    hash: String,
    signatureType: String
  }
}
</script>

<style scoped>

</style>
